import { TinodeConnectionSetup } from 'tinode-sdk';

export const environment = {
	apiURL: 'https://backend.platform.ameetee.io', // TODO: prod url,
	tinode: {
		connection: {
			apiKey: 'AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K',
			// host: 'localhost:6060',
			// host: 'tinode.cloud.technokratos.com',
			host: 'tinode.platform.ameetee.io',
			secure: true,
		} satisfies TinodeConnectionSetup,
	},
	userAgreement: 'https://minio.platform.ameetee.io/static/files/User_Agreement.pdf',
	privacyNotice: 'https://minio.platform.ameetee.io/static/files/Privacy_Notice.pdf',
	confidentialPolicy: 'https://minio.platform.ameetee.io/static/files/Confidentiality_Policy.pdf',
	NDACompany: 'https://minio.platform.ameetee.io/static/files/NDA%20buyer%20single%20company.pdf',
	NDAIndividual: 'https://minio.platform.ameetee.io/static/files/NDA%20buyer%20single%20natural%20person.pdf',
};
